import Base from './base.js'

export default class Invoice extends Base {

	constructor() {
		super();
		this.route = '/api/invoice'
	}

	all() {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(false)
			});
		})
	}

	detail(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}`, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(response.error)
			});
		})
	}

	create(data) {
		console.log('invoice creation')
		console.log(data)
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, data: data, method: 'POST'})
			.then((response) => {
				console.log(response.data)
				resolve(response.data)
			})
			.catch((error) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	update(data) {
		console.log('invoice updating:')
		console.log(data)
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${data.id}`, data: data, method: 'PUT'})
			.then((response) => {
				console.log(response.data)
				resolve(response.data)
			})
			.catch((error) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	delete(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}` , method: 'DELETE'})
			.then((response) => {
				// Resolve successfully
				resolve(true)
			})
			.catch((error) => {
				console.log(error.error)
				reject(false)
			});
		})
	}

	mailToLocation(id) {
        console.log('mail to location')
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}/mail-to-location`, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response)
			})
			.catch((response) => {
				reject(response.error)
			});
		})
	}

	mailReminderToLocation(id) {
        console.log('mail to location')
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}/mail-reminder-to-location`, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response)
			})
			.catch((response) => {
				reject(response.error)
			});
		})
	}

	download(id) {
        console.log('download')
        let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}/download`, method: 'GET', responseType: 'blob'})
			.then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `invoice-${id}.pdf`)
                document.body.appendChild(link)
				link.click()
				resolve(response.data)
            })
			.catch((response) => {
				reject(response.error)
            })
		})
	}

	billOpenBookings() {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + '/bill-open-bookings', method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(false)
			});
		})
	}

}
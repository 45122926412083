<template>
  <CRow>
    <CCol col="12" xl="12">

      <CButton variant="outline" type="submit" color="primary" class='list-new-btn'
        @click="$router.push({ path: '/manager/invoices/create' })" v-if="$store.getters.isAdmin">
        <CIcon name="cil-check-circle" />New invoice
      </CButton>

      <CButton variant="outline" type="submit" color="primary" class='list-new-btn' @click="billOpenBookings">
        <CIcon name="cil-check-circle" />Bill Open Bookings
      </CButton>

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Invoices</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable hover sorter striped table-filter :items="items" :fields="fields" :items-per-page="perPage"
              :pagination="$options.paginationProps" index-column>
              <template #location="{ item, index }">
                <td class="py-2">
                  <p v-if="item.location">{{ item.location.name }}</p>
                </td>
              </template>
              <template #total_price_inc="{ item, index }">
                <td class="py-2">
                  <p>€ {{ item.total_price_inc }}</p>
                </td>
              </template>
              <template #state="{ item, index }">
                <td>
                  <CBadge :color="getBadge(item.state)">
                    {{ item.state }}
                  </CBadge>
                </td>
              </template>
              <template #actions="{ item, index }">
                <td class="py-2">
                  <CButton color="primary" variant="outline" square size="sm" @click="detailClicked(item, index)">
                    Details
                  </CButton>
                </td>
              </template>

            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>

import InvoiceAPI from '/app/src/api/invoice.js'

export default {
  name: 'Invoices',
  data: () => {
    return {
      invoiceAPI: new InvoiceAPI(),
      items: [],
      fields: [
        { key: 'id' },
        { key: 'location_name' },
        { key: 'total_price_inc' },
        { key: 'state' },
        { key: 'actions' }
      ],
      perPage: 20,
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  created: function () {
    this.getInvoices()
  },
  methods: {
    getInvoices: function () {
      this.$store.dispatch('loading')
      let self = this
      this.invoiceAPI.all()
        .then((invoices) => {
          for (let i = 0; i < invoices.length; i++) {
            invoices[i].location_name = invoices[i].location.name
          }
          this.$store.dispatch('stopLoading')
          self.items = invoices

        })
        .catch((error) => {
          this.$store.dispatch('stopLoading')
          console.log(error)
        })
    },

    billOpenBookings: function () {
      this.$store.dispatch('loading')
      let self = this
      this.invoiceAPI.billOpenBookings()
        .then((invoices) => {
          this.$store.dispatch('stopLoading')
          this.$router.push({ path: '/manager/invoices' })
        })
        .catch((error) => {
          this.$store.dispatch('stopLoading')
          console.log(error)
        })
    },

    detailClicked(item, index) {
      const itemLink = `/manager/invoices/${item.id.toString()}`
      this.$router.push({ path: itemLink })
    },
    getBadge(state) {
      switch (state) {
        case 'paid':
          return 'success'
          break
        case 'unpaid':
          return 'warning'
          break
        case 'reminded':
          return 'danger'
          break
      }
    },
  }
}
</script>

